.c-page-views-table {
  @include mat-table-columns((concurrents: (width: 10%,
      ),
      title: (width: 79%,
      ),
      pageViews: (width: 11%,
      ),
    ));

  cursor: default;
}

.c-widgets-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      name: (width: 35%,
      ),
      updated: (width: 25%,
      ),
      active: (width: 5%,
      ),
      info: (width: 5%,
      ),
      statistics: (width: 8%,
      ),
      actions: (width: 12%,
      ),
    ));

  cursor: default;
}

.c-datasources-table {
  @include mat-table-columns((id: (width: 15%,
      ),
      name: (width: 20%,
      ),
      usedBy: (width: 20%,
      ),
      type: (width: 7.5%,
      ),
      updated: (width: 10%,
      ),
      active: (width: 5%,
      ),
      statistics: (width: 7.5%,
      ),
      actions: (width: 10%,
      ),
      export: (width: 5%,
      ),
    ));

  cursor: default;
}

.c-templates-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      name: (width: 40%,
      ),
      updated: (width: 10%,
      ),
      basic: (width: 5%,
      ),
      info: (width: 10%,
      ),
      actions: (width: 15%,
      ),
    ));

  cursor: default;
}

.c-triggers-table {
  @include mat-table-columns((id: (width: 18%,
      ),
      name: (width: 25%,
      ),
      type: (width: 12%,
      ),
      updated: (width: 13%,
      ),
      active: (width: 5%,
      ),
      info: (width: 5%,
      ),
      topic: (width: 5%,
      ),
      statistics: (width: 7%,
      ),
      actions: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-schedule-triggers-table {
  @include mat-table-columns((id: (width: 25%,
      ),
      name: (width: 20%,
      ),
      type: (width: 15%,
      ),
      updated: (width: 20%,
      ),
      active: (width: 10%,
      ),
      info: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-users-table {
  @include mat-table-columns((id: (width: 15%,
      ),
      name: (width: 15%,
      ),
      email: (width: 15%,
      ),
      lastSignIn: (width: 10%,
      ),
      role: (width: 15%,
      ),
      medias: (width: 15%,
      ),
      active: (width: 5%,
      ),
      actions: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-sites-table {
  @include mat-table-columns((id: (width: 5%,
      ),
      name: (width: 10%,
      ),
      account: (width: 10px,
      ),
      clicks: (width: 10px,
      ),
      impressions: (width: 10px,
      ),
      active: (width: 5%,
      ),
      actions: (width: 5%,
      ),
    ));

  cursor: default;
}

.c-medias-table {
  @include mat-table-columns((id: (width: 5%,
      ),
      name: (width: 15%,
      ),
      totalSites: (width: 10%,
      ),
      sites: (width: 50%,
      ),
      active: (width: 5%,
      ),
      permissions: (width: 25%,
      ),
      dimensions: (width: 20%,
      ),
      datasources: (width: 20%,
      ),
      actions: (width: 15%,
      ),
    ));

  cursor: default;
}

.c-segments-table {
  @include mat-table-columns((idSegment: (width: 10%,
      ),
      segmentName: (width: 20%,
      ),
      users: (width: 7%,
        text-align: center,
      ),
      active: (width: 7%,
      ),
      shared: (width: 15%,
        text-align: center,
      ),
      created: (width: 15%,
      ),
      updated: (width: 15%,
      ),
      operator: (width: 10%,
      ),
      statistics: (width: 8%,
      ),
      actions: (width: 12%,
      ),
      info: (width: 5%,
      ),
    ));

  cursor: default;
}

.c-segments-table-info-button {
  color: orange;
}

.c-real-time-segments-table {
  @include mat-table-columns((id: (width: 10%,
      ),
      name: (width: 25%,
      ),
      created: (width: 15%,
      ),
      updated: (width: 15%,
      ),
      status: (width: 5%,
      ),
      statistics: (width: 10%,
      ),
      actions: (width: 12%,
      ),
    ));

  cursor: default;
}

.c-imported-segments-table {
  @include mat-table-columns((id: (width: 10%,
      ),
      name: (width: 35%,
      ),
      created: (width: 10%,
      ),
      shared: (width: 10%,
      ),
      updated: (width: 15%,
      ),
      status: (width: 5%,
      ),
      statistics: (width: 5%,
      ),
    ));

  cursor: default;
}

.c-campaigns-table {
  @include mat-table-columns((campaign: (width: 30%,
      ),
      media: (width: 20%,
      ),
      impressions: (width: 20%,
      ),
      clicks: (width: 20%,
      ),
      CTR: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-newsletter-table {
  @include mat-table-columns((id: (width: 10%,
      ),
      title: (width: 30%,
      ),
      subject: (width: 30%,
      ),
      recipientCount: (width: 10%,
      ),
      schedule: (width: 10%,
      ),
      status: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-push-notification-table {
  @include mat-table-columns((id: (width: 10%,
      ),
      name: (width: 10%,
      ),
      subscribers: (width: 1%,
      ),
      statistics: (width: 10%,
      ),
      actions: (width: 5%,
      ),
    ));

  cursor: default;
}

.c-logs-table {
  @include mat-table-columns((created: (width: 15%,
      ),
      message: (width: 45%,
      ),
      user: (width: 15%,
      ),
      site: (width: 15%,
      ),
      actions: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-newsletters-subscriptions-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      title: (width: 30%,
      ),
      topic: (width: 15%,
      ),
      type: (width: 7%,
      ),
      updated: (width: 15%,
      ),
      active: (width: 5%,
      ),
      actions: (width: 15%,
      ),
    ));

  cursor: default;
}

.c-newsletters-audience-table {
  @include mat-table-columns((id: (width: 10%,
      ),
      name: (width: 25%,
      ),
      vendor: (width: 20%,
      ),
      vendorAudienceId: (width: 20%,
      ),
      active: (width: 10%,
      ),
      actions: (width: 15%,
      ),
    ));

  cursor: default;
}

.c-newsletters-topic-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      title: (width: 20%,
      ),
      fullName: (width: 10%,
      ),
      active: (width: 10%,
      ),
      subscriberCounter: (width: 10%,
      ),
      createBy: (width: 10%,
      ),
      actions: (width: 20%,
      ),
    ));

  cursor: default;
}

.c-push-subscriptions-table {
  @include mat-table-columns((id: (width: 15%,
      ),
      title: (width: 50%,
      ),
      topic: (width: 10%,
      ),
      type: (width: 7%,
      ),
      date: (width: 10%,
      ),
      name: (width: 23%,
      ),
      tag: (width: 15%,
      ),
      updated: (width: 15%,
      ),
      active: (width: 5%,
      ),
      statistics: (width: 7%,
      ),
      actions: (width: 15%,
      ),
    ));

  cursor: default;
}

.c-notification-push-web-table {
  @include mat-table-columns((id: (width: 15%,
      ),
      title: (width: 35%,
      ),
      topic: (width: 10%,
      ),
      date: (width: 15%,
      ),
      info: (width: 5%,
      ),
      actions: (width: 15%,
      ),
    ));
  cursor: default;
}

.c-push-apps-table {
  @include mat-table-columns((id: (width: 1%,
      ),
      title: (width: 23%,
      ),
      topic: (width: 15%,
      ),
      updated: (width: 15%,
      ),
      draft: (width: 5%,
      ),
      info: (width: 5%,
      ),
      actions: (width: 15%,
      ),
    ));

  cursor: default;
}

.mat-table {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  .mat-header-row {
    background-color: $gray;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.c-token-apps-table {
  @include mat-table-columns((groupId: (width: 5%,
      ),
      mediaId: (width: 5%,
      ),
      token: (width: 50%,
      ),
      active: (width: 5%,
      ),
      actions: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-products-table {
  @include mat-table-columns((product_id: (width: 5%,
      ),
      title: (width: 15%,
      ),
      type: (width: 15%,
      ),
      initialPrice: (width: 12%,
      ),
      renovationPrice: (width: 12%,
      ),
      stock: (width: 10%,
      ),
      visible: (width: 8%,
      ),
      active: (width: 8%,
      ),
      actions: (width: 15%,
      ),
    ));

  cursor: default;
}

.c-product-configs-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      title: (width: 40%,
      ),
      visible: (width: 10%,
      ),
      active: (width: 10%,
      ),
      actions: (width: 20%,
      ),
    ));

  cursor: default;
}

.c-subscribers-table {
  @include mat-table-columns((id: (width: 10%,
      ),
      firstName: (width: 20%,
      ),
      lastName1: (width: 20%,
      ),
      lastName2: (width: 20%,
      ),
      email: (width: 20%,
      ),
      actions: (width: 10%,
      ),
    ));

  cursor: default;
}

.c-paywall-campaigns-table {
  @include mat-table-columns((id: (width: 25%,
      ),
      name: (width: 25%,
      ),
      products: (width: 10%,
      ),
      sales: (width: 10%,
      ),
      active: (width: 15%,
      ),
      actions: (width: 15%,
      ),
    ));
}

.c-paywall-taxes-table {
  @include mat-table-columns((id: (width: 25%,
      ),
      name: (width: 30%,
      ),
      percentage: (width: 15%,
      ),
      active: (width: 15%,
      ),
      actions: (width: 15%,
      ),
    ));
}

.c-widget-articles-statistics-table {
  @include mat-table-columns((articleTitle: (width: 55%,
      ),
      articleSignature: (width: 10%,
      ),
      articleUrl: (width: 70%,
      ),
      articlePosition: (width: 5%,
      ),
      shows: (width: 10%,
      ),
      clics: (width: 10%,
      ),
      ctr: (width: 10%,
      ),
    ));
}

.c-statistics-widgetSegments-table {
  @include mat-table-columns((segmentId: (width: 64%,
      ),
      shows: (width: 10%,
      ),
      clics: (width: 10%,
      ),
      ctr: (width: 10%,
      ),
      statistics: (width: 6%,
      ),
    ));
}

.c-statistics-triggerArticles-table {
  @include mat-table-columns((articleTitle: (width: 30%,
      ),
      category: (width: 20%,
      ),
      shows: (width: 10%,
      ),
      action: (width: 10%,
      ),
      close: (width: 10%,
      ),
      action1: (width: 10%,
      ),
      action2: (width: 10%,
      ),
    ));
}

.c-statistics-triggerCategories-table {
  @include mat-table-columns((name: (width: 50%,
      ),
      shows: (width: 10%,
      ),
      close: (width: 10%,
      ),
      action: (width: 10%,
      ),
      action1: (width: 10%,
      ),
      action2: (width: 10%,
      ),
    ));
}

.c-statistics-triggerSegments-table {
  @include mat-table-columns((segmentId: (width: 44%,
      ),
      shows: (width: 10%,
      ),
      close: (width: 10%,
      ),
      action: (width: 10%,
      ),
      action1: (width: 10%,
      ),
      action2: (width: 10%,
      ),
      statistics: (width: 6%,
      ),
    ));
}

.c-statistics-datasourceDomain-table {
  @include mat-table-columns((domains: (width: 40%,
      ),
      shows: (width: 25%,
      ),
      clics: (width: 25%,
      ),
      ctr: (width: 10%,
      ),
    ));
}

.c-statistics-datasourceArticle-table {
  @include mat-table-columns((articleImage: (width: 10%,
      ),
      articleTitle: (width: 60%,
      ),
      impressions: (width: 10%,
      ),
      clicks: (width: 10%,
      ),
      CTR: (width: 10%,
      ),
    ));
}

.c-statistics-segments-components-table {
  @include mat-table-columns((componentId: (width: 20%,
      ),
      componentType: (width: 19%,
      ),
      componentName: (width: 35%,
      ),
      totalImpacts: (width: 10%,
      ),
      pvPercentage: (width: 10%,
      ),
      statistics: (width: 6%,
      ),
    ));
}

.c-statistics-push-top-content-components-table {
  @include mat-table-columns((eventUrl: (width: 34%,
      ),
      topic: (width: 12%,
      ),
      eventDate: (width: 8%,
      ),
      eventTime: (width: 8%,
      ),
      received: (width: 10%,
      ),
      'open': (width: 14%,
      ),
      'closed': (width: 14%,
      ),
    ));
}

.c-statistics-datasourceChildren-table {
  @include mat-table-columns((datasourceId: (width: 19%,
      ),
      datasourceName: (width: 30%,
      ),
      impressions: (width: 15%,
      ),
      clicks: (width: 15%,
      ),
      CTR: (width: 15%,
      ),
      statistics: (width: 6%,
      ),
    ));
}

.c-roles-table {
  @include mat-table-columns((id: (width: 25%,
      ),
      name: (width: 25%,
      ),
      permissions: (width: 10%,
      ),
      visible: (width: 10%,
      ),
      actions: (width: 30%,
      ),
    ));
}

.c-triggers-template-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      name: (width: 15%,
      ),
      type: (width: 10%,
      ),
      updated: (width: 15%,
      ),
      active: (width: 10%,
      ),
      basic: (width: 10%,
      ),
      info: (width: 5%,
      ),
      actions: (width: 15%,
      ),
    ));
}

.c-push-notification-template-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      name: (width: 15%,
      ),
      title: (width: 15%,
      ),
      subject: (width: 15%,
      ),
      updated: (width: 15%,
      ),
      active: (width: 10%,
      ),
      isBasic: (width: 10%,
      ),
      actions: (width: 15%,
      ),
    ));
}

.c-utm-campaigns-table {
  @include mat-table-columns((id: (width: 15%,
      ),
      name: (width: 10%,
      ),
      value: (width: 15%,
      ),
      availableIn: (width: 15%,
      ),
      mediaId: (width: 10%,
      ),
      usedBy: (width: 18%,
      ),
      updatedBy: (width: 13%,
      ),
      active: (width: 5%,
      ),
      actions: (width: 13%,
      ),
    ));
}

.c-paywall-products-table {
  @include mat-table-columns((id: (width: 15%,
      ),
      name: (width: 20%,
      ),
      type: (width: 15%,
      ),
      stock: (width: 10%,
      ),
      active: (width: 6%,
      ),
      showcase: (width: 6%,
      ),
      newClients: (width: 6%,
      ),
      subscribers: (width: 6%,
      ),
      exSubscribers: (width: 6%,
      ),
      actions: (width: 10%,
      ),
    ));
}

.c-paywall-retroactivity-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      product: (width: 20%,
      ),
      start: (width: 15%,
      ),
      end: (width: 15%),
      status: (width: 10%,
      ),
      unarchive: (width: 10%,
      ),
      download: (width: 10%,
      ),
    ));
}

.c-paywall-customers-table {
  @include mat-table-columns((id: (width: 15%),
      gigyaUid: (width: 20%),
      name: (width: 20%),
      email: (width: 20%),
      newClient: (width: 5%),
      subscriber: (width: 5%),
      exSubscriber: (width: 5%),
      actions: (width: 10%)));
}

.c-paywall-rrm-customers-table {
  @include mat-table-columns((id: (width: 20%),
      gigyaUid: (width: 20%),
      readerId: (width: 20%),
      email: (width: 25%),
      actions: (width: 15%)));
}

.c-paywall-campaigns-products-table {
  @include mat-table-columns((id: (width: 30%,
      ),
      name: (width: 30%,
      ),
      sales: (width: 20%,
      ),
      actions: (width: 20%,
      ),
    ));
}

.c-paywall-subscriptions-table {
  @include mat-table-columns((id: (width: 14%,
      ),
      customerId: (width: 14%,
      ),
      start: (width: 10%,
      ),
      endedAt: (width: 10%,
      ),
      product: (width: 10%,
      ),
      amount: (width: 10%,
      ),
      renewable: (width: 7%,
      ),
      campaign: (width: 7%,
      ),
      status: (width: 7%,
      ),
      actions: (width: 10%,
      ),
    ));
}

.c-admin-dimensions-table {
  @include mat-table-columns((id: (width: 25%,
      ),
      title: (width: 30%,
      ),
      parameters: (width: 30%,
      ),
      actions: (width: 15%,
      ),
    ));
}

.c-admin-roles-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      title: (width: 30%,
      ),
      permissions: (width: 20%,
      ),
      visible: (width: 10%,
      ),
      actions: (width: 15%,
      ),
    ));
}

.c-paywall-subscription-detail-support {
  @include mat-table-columns((date: (width: 15%,
      ),
      email: (width: 15%,
      ),
      type: (width: 15%,
      ),
      reason: (width: 20%,
      ),
      comment: (width: 35%,
      ),
    ));
}

.c-paywall-subscription-detail-renovations {
  @include mat-table-columns((period: (width: 25%,
      ),
      status: (width: 10%,
      ),
      amount: (width: 10%,
      ),
      paidAt: (width: 25%,
      ),
      actions: (width: 15%,
      ),
    ));
}

.c-retail-media-campaigns-table {
  @include mat-table-columns((id: (width: 12%,
      ),
      name: (width: 12%,
      ),
      dateInit: (width: 8%,
      ),
      dateEnd: (width: 8%,
      ),
      impressions: (width: 5%,
      ),
      advertiser: (width: 7%,
      ),
      vendor: (width: 7%,
      ),
      updatedBy: (width: 13%,
      ),
      state: (width: 8%,
      ),
      usedByMedias: (width: 7%,
      ),
      owner: (width: 4%,
      ),
      actions: (width: 9%,
      ),
    ));
}

.c-paywall-schedules-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      customerId: (width: 15%,
      ),
      product: (width: 15%,
      ),
      scheduledStart: (width: 10%,
      ),
      amount: (width: 5%,
      ),
      renewable: (width: 5%,
      ),
      campaign:(width: 10%),
      status:(width: 10%),
      actions: (width: 10%,
      ),
    ));
}

.c-paywall-customers-schedules-table {
  @include mat-table-columns((id: (width: 20%,
      ),
      subscriptor: (width: 15%,
      ),
      scheduledStart: (width: 10%,
      ),
      product: (width: 15%,
      ),
      amount: (width: 10%,
      ),
      renewable: (width: 5%,
      ),
      status:(width: 5%),
      campaign:(width: 10%),
      actions: (width: 10%,
      ),
    ));
}

.c-paywall-operations-table {
  @include mat-table-columns((id: (width: 17%),
      paymentDate: (width: 8%),
      amount: (width: 5%),
      period: (width: 8%),
      customer: (width: 15%),
      productId: (width: 15%),
      subscription: (width: 17%),
      status: (width: 5%),
      actions: (width: 10%)))
}

.c-paywall-customer-operations-table {
  @include mat-table-columns((id: (width: 15%),
      period: (width: 15%),
      product: (width: 15%),
      email: (width: 10%),
      amount: (width: 10%),
      status: (width: 10%),
      paymentDate: (width: 10%),
      renewable: (width: 5%),
      actions: (width: 10%)))
}

.c-paywall-customer-subscriptions-rrm-table {
  @include mat-table-columns((planId: (width: 30%),
      planType: (width: 25%),
      expireTime: (width: 25%),
      actions: (width: 20%)))
}

.c-paywall-customer-operations-rrm-table {
  @include mat-table-columns((orderId: (width: 20%),
      planId: (width: 25%),
      autoRenewing: (width: 5%),
      purchaseTime: (width: 10%),
      productId: (width: 25%),
      actions: (width: 15%)))
}

.c-paywall-refunds-table {
  @include mat-table-columns((id:(width: 15.5%),
      created: (width: 7%),
      customer: (width: 15%),
      product: (width: 12.5%),
      subscription: (width: 17.5%),
      status: (width: 7.5%),
      refunded: (width: 5%),
      operation: (width: 20%)))
}

.c-paywall-subscriptions-details-support {
  @include mat-table-columns((date: (width: 10%,
      ),
      email: (width: 15%,
      ),
      type: (width: 20%,
      ),
      reason: (width: 20%,
      ),
      comment: (width: 35%,
      ),
    ));
}

.c-data-clean-room-operations-table {
  @include mat-table-columns((idSegment: (width: 8%,
      ),
      segmentName: (width: 14%,
      ),
      shared: (width: 13%,
      ),
      advertiserShared: (width: 14%,
      ),
      nUsers: (width: 5%,
      ),
      adManagerSegment: (width: 5%,
      ),
      xandr: (width: 5%,
      ),
      alayansLink: (width: 6%,
      ),
      hipatia: (width: 5%,
      ),
      updatedBy: (width: 10%,
      ),
      active: (width: 5%,
      ),
      actions: (width: 10%,
      ),
    ));
}

.c-data-clean-room-segments-table {
  @include mat-table-columns((id: (width: 8%,
      ),
      name: (width: 15%,
      ),
      mediaShared: (width: 15%,
      ),
      advertiserShared: (width: 15%,
      ),
      usersQuantity: (width: 5%,
      ),
      gam: (width: 5%,
      ),
      xandr: (width: 5%,
      ),
      alayansLink: (width: 7%,
      ),
      hipatia: (width: 5%,
      ),
      updatedBy: (width: 15%,
      ),
      state: (width: 5%,
      ),
    ));
}

.c-data-clean-room-operations-table {
  @include mat-table-columns((idSegment: (width: 6%,
      ),
      segmentName: (width: 17%,
      ),
      shared: (width: 11%,
      ),
      advertiserShared: (width: 13%,
      ),
      nUsers: (width: 5%,
      ),
      adManagerSegment: (width: 5%,
      ),
      xandr: (width: 4%,
      ),
      alayansLink: (width: 5%,
      ),
      hipatia: (width: 4%,
      ),
      updatedBy: (width: 15%,
      ),
      active: (width: 5%,
      ),
      actions: (width: 9%,
      ),
    ));
}

.c-data-clean-room-segments-table {
  @include mat-table-columns((id: (width: 10%,
      ),
      name: (width: 14%,
      ),
      mediaShared: (width: 11%,
      ),
      advertiserShared: (width: 14%,
      ),
      usersQuantity: (width: 7%,
      ),
      gam: (width: 7%,
      ),
      xandr: (width: 7%,
      ),
      alayansLink: (width: 7%,
      ),
      hipatia: (width: 7%,
      ),
      updatedBy: (width: 10%,
      ),
      state: (width: 6%,
      ),
    ));
}

.c-paywall-gigya-table {
  @include mat-table-columns((uid: (width: 30%),
      email: (width: 30%),
      name: (width: 30%),
      actions: (width: 10%)))
}

.c-paywall-customer-form-support {
  @include mat-table-columns((date: (width: 8%),
      email: (width: 15%),
      contractable: (width: 17%),
      type: (width: 13%),
      reason: (width: 15%),
      comment: (width: 32%),
    ))
}

.c-paywall-rrm-subscriptions-table {
  @include mat-table-columns((id: (width: 20%),
      expireTime: (width: 10%),
      customerId: (width: 20%),
      email: (width: 20%),
      lastEvent: (width: 20%),
      actions: (width: 10%),
    ))
}

.c-paywall-rrm-operations-table {
  @include mat-table-columns((orderId: (width: 20%),
      planId: (width: 20%),
      autoRenewing: (width: 5%),
      purchaseTime: (width: 10%),
      productId: (width: 15%),
      readerId: (width: 20%),
      actions: (width: 10%)))
}

.c-paywall-events-table {
  @include mat-table-columns((occurredOn: (width: 10%),
      event: (width: 10%),
      customer: (width: 15%),
      product: (width: 15%),
      subscription: (width: 18%),
      operation: (width: 17%),
      amount: (width: 5%),
      actions: (width: 10%)))
}

.c-thalia-prompts-table {
  @include mat-table-columns((id: (width: 10%),
      name: (width: 10%),
      versions: (width: 5%),
      approvedVersion: (width: 10%),
      medias: (width: 25%),
      createdBy: (width: 15%),
      updatedBy: (width: 15%),
      actions: (width: 10%)))
}

.c-thalia-models-content-generation-table {
  @include mat-table-columns((
    id: (width: 15%),
    model: (width: 15%),
    medias: (width: 30%),
    totalModels: (width: 5%),
    updated: (width: 25%),
    actions: (width: 10%)))
}

.c-thalia-models-transcript-table {
  @include mat-table-columns((
    id: (width: 15%),
    model: (width: 25%),
    medias: (width: 25%),
    active: (width: 5%),
    updatedBy: (width: 20%),
    actions: (width: 10%)))
}

.c-thalia-transcript-table {
  @include mat-table-columns((
    id: (width: 10%),
    name: (width: 30%),
    file: (width: 25%),
    created: (width: 25%),
    actions: (width: 10%)))
}

.c-thalia-content-table {
  @include mat-table-columns((
    id: (width: 10%),
    model: (width: 15%),
    promptName: (width: 15%),
    title: (width: 30%),
    created: (width: 20%),
    actions: (width: 10%)))
}

.c-paywall-configuration-versions {
  @include mat-table-columns((
    id: (width: 25%),
    timestamp: (width: 25%),
    user: (width: 25%),
    actions: (width: 25%)))
}

.c-thalia-report-content-group-table {
  @include mat-table-columns((
    created: (width: 25%),
    group: (width: 25%),
    site: (width: 25%),
    contentNumber: (width: 25%),
  ))
}

.c-thalia-report-content-model-table {
  @include mat-table-columns((
    created: (width: 20%),
    model: (width: 20%),
    group: (width: 20%),
    site: (width: 20%),
    contentNumber: (width: 20%),
  ))
}

.c-thalia-report-transcript-group-table {
  @include mat-table-columns((
    created: (width: 25%),
    group: (width: 25%),
    site: (width: 25%),
    minutes: (width: 25%),
  ))
}

.c-thalia-report-transcript-model-table {
  @include mat-table-columns((
    created: (width: 20%),
    model: (width: 20%),
    group: (width: 20%),
    site: (width: 20%),
    minutes: (width: 20%),
  ))
}

.c-thalia-report-prompt-table {
  @include mat-table-columns((
    created: (width: 25%),
    id: (width: 15%),
    prompt: (width: 35%),
    contentNumber: (width: 25%),
  ))
}